import { useCallback, useEffect, useMemo, useState } from 'react';

import { useUtilityContext } from '@change-corgi/core/react/utilityContext';

import { usePetitionDetailsPageData } from 'src/app/pages/petitionGamma/details/pageContext';
import { getIframelyData } from 'src/app/shared/api/medias';
import { useCampaignTracking } from 'src/app/shared/hooks/campaignTracking';

import type { MediaHitComment, MediaHitComments, MediaHitEmbed, MediaHitsContextValue } from './types';

// eslint-disable-next-line max-lines-per-function
export function useMediaHitsContextValue(): MediaHitsContextValue {
	const [mediaHitComments, setMediaHitComments] = useState<MediaHitComments | undefined>();
	const [mediaEmbeds, setMediaEmbeds] = useState<MediaHitEmbed[]>([]);
	const [loadingAllEmbeds, setLoadingAllEmbeds] = useState(false);
	const [submitStatus, setSubmitStatus] = useState<'success' | 'error' | undefined>();
	const [newlyAddedMedia, setNewlyAddedMedia] = useState<MediaHitComment | undefined>();
	const [loadingSingleEmbed, setLoadingSingleEmbed] = useState(false);

	const {
		data: {
			petition: { id: petitionId },
			mediaHitsPage: { mediaHits: mediaHitPageComments },
		},
	} = usePetitionDetailsPageData();
	const utilityContext = useUtilityContext();
	const { track } = useCampaignTracking();

	const loadMedias = useCallback(
		async (mediaHits: MediaHitComments | undefined) => {
			if (mediaHits) {
				setLoadingAllEmbeds(true);
				const mediasPromises = mediaHits.map(async ({ url, id, mediaSource }) => {
					try {
						const response = await getIframelyData({ url, format: 'BIG' }, utilityContext);
						return { valid: true, id, url, mediaSource, html: response.html };
					} catch (err) {
						// do not throw when iframely fails to generate an embed
						// either due to an broken website, article removed, network failure,etc
						// but catch the error and return an object with an valid: false property
						return { valid: false, id, url, mediaSource, html: '' };
					}
				});
				const petitionMedias = await Promise.all(mediasPromises);

				setLoadingAllEmbeds(false);
				setMediaEmbeds(petitionMedias);
			}
		},
		[utilityContext],
	);

	const loadSingleMedia = useCallback(
		async (newMediaHit: MediaHitComment | undefined) => {
			if (newMediaHit) {
				const { id, url, mediaSource } = newMediaHit;
				setLoadingSingleEmbed(true);
				try {
					const response = await getIframelyData({ url, format: 'BIG' }, utilityContext);
					setMediaEmbeds((curState) => [{ valid: true, id, url, mediaSource, html: response.html }, ...curState]);
				} catch {
					track('media_hit_iframely_embed_failure', {
						petition_id: petitionId,
						media_url: url,
					});
					setMediaEmbeds((curState) => [{ valid: false, id, url, mediaSource, html: '' }, ...curState]);
				} finally {
					setLoadingSingleEmbed(false);
				}
			}
		},
		[utilityContext, track, petitionId],
	);

	useEffect(() => {
		setMediaHitComments([...mediaHitPageComments]);
	}, [mediaHitPageComments]);

	useEffect(() => {
		void loadMedias(mediaHitComments);
	}, [mediaHitComments, loadMedias]);

	useEffect(() => {
		void loadSingleMedia(newlyAddedMedia);
	}, [newlyAddedMedia, loadSingleMedia]);

	const removeMedia = useCallback(
		(mediaId: string) => {
			const remainingMediaEmbeds = mediaEmbeds.filter((media) => media.id !== mediaId);
			setMediaEmbeds(remainingMediaEmbeds);
		},
		[mediaEmbeds],
	);

	const addMedia = useCallback((id: string, url: string) => {
		setNewlyAddedMedia({ id, url, mediaSource: 'STAFF_ADDED' });
	}, []);

	const value = useMemo(
		() => ({
			data: {
				mediaEmbeds,
				mediaHitComments,
				loadingAllEmbeds,
				loadingSingleEmbed,
				submitStatus,
			},
			actions: {
				addMedia,
				removeMedia,
				setSubmitStatus,
				setNewlyAddedMedia,
			},
		}),
		[
			mediaEmbeds,
			mediaHitComments,
			loadingAllEmbeds,
			loadingSingleEmbed,
			submitStatus,
			addMedia,
			removeMedia,
			setSubmitStatus,
		],
	);

	return value;
}
