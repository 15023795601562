import type { JSX } from 'react';

import { alpha } from '@theme-ui/color';

import { Translate, TranslateHtml } from '@change-corgi/core/react/i18n';
import { Icon } from '@change-corgi/design-system/components/icon';
import { iconSentimentSatisfiedAlt, iconSentimentVeryDissatisfied } from '@change-corgi/design-system/icons';
import { Box, Flex } from '@change-corgi/design-system/layout';
import { Text } from '@change-corgi/design-system/typography';

import type { DecisionMaker } from 'src/app/pages/petitionGamma/details/shared/types';

import type { AllDmResponses } from '../../../../hooks/useDecisionMakerCards';
import { useDmResponse } from '../../shared/hooks/useDmResponse';

import { NotifiedSince } from './NotifiedSince';
import { RespondAsNewDm } from './RespondAsNewDm';

type DmResponseStatusProps = {
	decisionMaker: DecisionMaker;
	allDmResponses: AllDmResponses;
};

export function DmResponseStatus({ decisionMaker, allDmResponses }: DmResponseStatusProps): JSX.Element {
	const {
		data: { dmHasResponded, showRespondAsNewDm },
	} = useDmResponse({ decisionMaker, allDmResponses });

	return (
		<Box data-testid="dm-response-status" mb={8}>
			{dmHasResponded ? (
				<Flex
					data-testid="dm-status-responded"
					sx={{ background: alpha('social-whatsapp', 0.2), borderRadius: 'standard', alignItems: 'center' }}
					px={8}
					py={4}
				>
					<Icon icon={iconSentimentSatisfiedAlt} mr={8} />
					<Text size="small">
						<Translate value="corgi.petition_gamma.decision_makers.response_status.responded" />
					</Text>
				</Flex>
			) : (
				<Flex
					data-testid="dm-status-not-responded"
					sx={{ background: '#FFE5E5', borderRadius: 'standard', alignItems: 'center' }}
					px={8}
					py={4}
				>
					<Icon icon={iconSentimentVeryDissatisfied} mr={8} />
					<Box>
						<Text size="small" as="p" fontWeight="normal">
							<TranslateHtml value="corgi.petition_gamma.decision_makers.response_status.not_responded" />{' '}
							<NotifiedSince daysSinceNotified={decisionMaker.targetPetitionAssociationData?.daysSinceNotified} />
						</Text>
						{showRespondAsNewDm && <RespondAsNewDm dmName={decisionMaker.displayName} />}
					</Box>
				</Flex>
			)}
		</Box>
	);
}
