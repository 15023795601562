import { type ComponentPropsWithoutRef, useCallback } from 'react';

import { useTracking } from '@change-corgi/core/react/tracking';
import { FakeLink } from '@change-corgi/design-system/components/actions';
import { Separator } from '@change-corgi/design-system/components/content';
import { Box, Flex } from '@change-corgi/design-system/layout';

import { usePetitionDetailsPageData } from 'src/app/pages/petitionGamma/details/pageContext';
import { ResponsiveBox } from 'src/app/pages/petitionGamma/details/shared/components';

import type { SectionId } from '../../../../hooks/useScrollToSection';
import { SECTION_ID_MAPPING, useScrollToSection } from '../../../../hooks/useScrollToSection';
import { useContentSummary } from '../../hooks/useContentSummary';
import { DecisionMakersText } from '../DecisionMakersText';
import { MediaHitsText } from '../MediaHitsText';
import { SupporterVoicesText } from '../SupporterVoicesText';
import { UpdatesText } from '../UpdatesText';
import { VotersInfo } from '../VotersInfo';

import { MicrophoneIcon } from './icons/MicrophoneIcon';
import { NewspaperHeartMiddleIcon } from './icons/NewspaperHeartMiddleIcon';
import { USCapitolIcon } from './icons/USCapitolIcon';
import { VoicesIcon } from './icons/VoicesIcon';
import { VotersIcon } from './icons/VotersIcon';

const ContentSummaryItem: React.FC<ComponentPropsWithoutRef<typeof Box>> = ({ children, ...rest }) => {
	return (
		<Flex
			sx={{
				gap: [16, 8],
				flexBasis: 0,
				flexGrow: 1,
				flexDirection: ['row', 'column'],
				borderColor: 'primary-greyBackground',
				borderLeftStyle: ['none', 'solid'],
				borderLeftWidth: [0, 1],
				paddingLeft: [0, 16],
				// eslint-disable-next-line @typescript-eslint/naming-convention
				':first-of-type': {
					border: 'none',
					paddingLeft: 0,
				},
			}}
			{...rest}
		>
			{children}
		</Flex>
	);
};

const VotersSection: React.FC<{ voters: { percentage: number; isShown: boolean } }> = ({ voters }) => (
	<ContentSummaryItem data-testid="content-summary-voters">
		<VotersIcon fontSize={24} />
		<VotersInfo percentage={voters.percentage} />
	</ContentSummaryItem>
);

const DecisionMakersSection: React.FC<{
	decisionMakers: {
		first: {
			displayName: string;
		};
		count: number;
	};
	scroll: (id: SectionId) => () => void;
}> = ({ decisionMakers, scroll }) => (
	<ContentSummaryItem data-testid="content-summary-decision-makers">
		<USCapitolIcon fontSize={24} />
		<FakeLink
			data-testid="content-summary-decision-makers-link"
			data-qa="content-summary-decision-makers-link"
			onClick={scroll(SECTION_ID_MAPPING.DECISION_MAKERS)}
			sx={{
				display: 'inline-block',
				fontWeight: 380,
				color: 'black',
				textAlign: 'start',
			}}
		>
			<DecisionMakersText firstDmName={decisionMakers.first.displayName} count={decisionMakers.count} />
		</FakeLink>
	</ContentSummaryItem>
);

const UpdatesSection: React.FC<{
	updates: {
		count: number;
	};
	scroll: (id: SectionId) => () => void;
}> = ({ updates, scroll }) => (
	<ContentSummaryItem
		data-testid="content-summary-updates"
		data-qa="content-summary-updates"
		onClick={scroll(SECTION_ID_MAPPING.UPDATES)}
	>
		<NewspaperHeartMiddleIcon fontSize={24} />
		<FakeLink
			data-testid="content-summary-updates-link"
			data-qa="content-summary-updates-link"
			onClick={scroll(SECTION_ID_MAPPING.UPDATES)}
			sx={{
				display: 'inline-block',
				fontWeight: 380,
				color: 'black',
				textAlign: 'start',
			}}
		>
			<UpdatesText count={updates.count} />
		</FakeLink>
	</ContentSummaryItem>
);

const MediaHitsSection: React.FC<{
	mediaHits: {
		count: number;
	};
	scroll: (id: SectionId) => () => void;
}> = ({ mediaHits, scroll }) => (
	<ContentSummaryItem data-testid="content-summary-media-mentions">
		<MicrophoneIcon fontSize={24} />
		<FakeLink
			data-testid="content-summary-media-mentions-link"
			data-qa="content-summary-media-mentions-link"
			onClick={scroll(SECTION_ID_MAPPING.MEDIA_MENTIONS)}
			sx={{
				display: 'inline-block',
				fontWeight: 380,
				color: 'black',
				textAlign: 'start',
			}}
		>
			<MediaHitsText count={mediaHits.count} />
		</FakeLink>
	</ContentSummaryItem>
);

const SupporterVoicesSection: React.FC<{
	supporterVoices: {
		count: number;
	};
	scroll: (id: SectionId) => () => void;
}> = ({ supporterVoices, scroll }) => (
	<ContentSummaryItem data-testid="content-summary-supporter-voices">
		<VoicesIcon fontSize={24} />
		<FakeLink
			data-testid="content-summary-supporter-voices-link"
			data-qa="content-summary-supporter-voices-link"
			onClick={scroll(SECTION_ID_MAPPING.SUPPORTER_VOICES)}
			sx={{
				display: 'inline-block',
				fontWeight: 380,
				color: 'black',
				textAlign: 'start',
			}}
		>
			<SupporterVoicesText count={supporterVoices.count} />
		</FakeLink>
	</ContentSummaryItem>
);

type Props = EmptyObject;

export const ContentSummary: React.FC<Props> = () => {
	const {
		data: { sectionIsShown, decisionMakers, mediaHits, updates, voters, supporterVoices },
	} = useContentSummary();
	const {
		data: {
			petition: { id: petitionId },
		},
	} = usePetitionDetailsPageData();

	const {
		actions: { scrollToSection },
	} = useScrollToSection();
	const track = useTracking();

	const scroll = useCallback(
		(id: SectionId) => () => {
			void track('petition_page_content_summary_click', {
				petition_id: petitionId,
				link_type: id,
				ids: id === 'decision-makers' ? decisionMakers.dms.map((dm) => dm.id) : [],
			});
			scrollToSection(id);
		},
		[decisionMakers.dms, petitionId, scrollToSection, track],
	);

	if (!sectionIsShown) {
		return null;
	}

	return (
		<>
			<ResponsiveBox viewportSize="small">
				<Separator role="presentation" mt={16} />
			</ResponsiveBox>
			<Box px={[24, 0]} py={24} mt={[0, 24]} data-qa="content-summary-section">
				<Flex
					data-testid="content-summary"
					sx={{
						gap: 16,
						flexDirection: ['column', 'row'],
						justifyContent: ['space-around', 'space-between'],
					}}
				>
					{voters.isShown && <VotersSection voters={voters} />}
					{decisionMakers.isShown && <DecisionMakersSection decisionMakers={decisionMakers} scroll={scroll} />}
					{supporterVoices.isShown && <SupporterVoicesSection supporterVoices={supporterVoices} scroll={scroll} />}
					{updates.isShown && <UpdatesSection updates={updates} scroll={scroll} />}
					{mediaHits.isShown && <MediaHitsSection mediaHits={mediaHits} scroll={scroll} />}
				</Flex>
			</Box>
			<ResponsiveBox viewportSize="small">
				<Separator role="presentation" mb={16} />
			</ResponsiveBox>
		</>
	);
};
