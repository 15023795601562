import type { JSX } from 'react';

import { withPrefetchedData } from 'src/shared/prefetch';
import type { PrefetchedUserDataProps } from 'src/shared/prefetch';

import { CountryBannerContainer } from 'src/app/shared/components/banners/country';
import type { PrefetchedStarterBannerData } from 'src/app/shared/components/banners/starterDashPrompt';
import {
	prefetchStarterDashboardPromptingBanner,
	StarterDashPromptingBanner,
	useStarterDashboardPromptingBanner,
} from 'src/app/shared/components/banners/starterDashPrompt';
import { prefetchCountryBanner, useCountryBanner } from 'src/app/shared/hooks/banners/country';
import { isLoaded, isLoading } from 'src/app/shared/utils/async';

import { EmergencyBannerContainer } from './EmergencyBanner';

type Props = Readonly<{
	petitionId: string;
	signed: boolean;
	emergencyBanner?: string;
}>;

export const BannerContainer = withPrefetchedData(
	({
		emergencyBanner,
		petitionId,
		signed,
		prefetchedUserData,
	}: Props & PrefetchedUserDataProps<PrefetchedStarterBannerData>): JSX.Element | null => {
		const countryBanner = useCountryBanner('petition', { id: petitionId, signed });
		const starterDashBanner = useStarterDashboardPromptingBanner(prefetchedUserData);

		if (emergencyBanner) {
			return <EmergencyBannerContainer emergencyBanner={emergencyBanner} />;
		}

		if (isLoading(starterDashBanner)) return null;

		if (isLoaded(starterDashBanner) && starterDashBanner.enabled) {
			return (
				<StarterDashPromptingBanner
					location="petition"
					bannerContext={{
						petitionId: starterDashBanner.petitionId,
						petitionSlug: starterDashBanner.petitionSlug,
					}}
				/>
			);
		}

		if (countryBanner) {
			return <CountryBannerContainer petitionId={petitionId} context="petition" countryBanner={countryBanner} />;
		}

		return null;
	},
	{
		prefetchName: __MODULE_HASH__,
		prefetchData: async (context) => {
			await Promise.all([prefetchCountryBanner(context)]);
		},
		prefetchUserData: async (context) => {
			const starterDashBanner = await prefetchStarterDashboardPromptingBanner(context);
			return { ...starterDashBanner };
		},
	},
);
