import { Translate } from '@change-corgi/core/react/i18n';
import { FakeLink } from '@change-corgi/design-system/components/actions';
import { Box, Flex } from '@change-corgi/design-system/layout';
import { Text } from '@change-corgi/design-system/typography';

import type { SupporterComment } from '../../../../shared/types';

import { useIsCollapsed } from './hooks/useIsCollapsed';
import { useIsCommentTooLong } from './hooks/useIsCommentTooLong';

const COLLAPSED_STYLE = {
	maxHeight: '140px',
	overflow: 'hidden',
	backgroundImage: 'linear-gradient(black 75%, white)',
	color: 'transparent',
	backgroundClip: 'text',
};
const EXPANDED_STYLE = {
	height: 'auto',
};

export function SupporterCommentCardBody({
	supporterComment,
}: {
	supporterComment: SupporterComment;
}): React.JSX.Element {
	const isCommentTooLong = useIsCommentTooLong(supporterComment);
	const {
		data: { isCollapsed },
		actions: { expand },
	} = useIsCollapsed();

	return (
		<Flex sx={{ gap: '15px' }}>
			<Box sx={{ backgroundColor: 'primary-changeRed', minWidth: '3px' }}></Box>
			<Box>
				<Box
					data-testid={`supporter-comment-body-${supporterComment.id}`}
					sx={isCommentTooLong && isCollapsed ? COLLAPSED_STYLE : EXPANDED_STYLE}
				>
					<Text as="q" sx={{ fontStyle: 'italic' }}>
						{supporterComment.comment}
					</Text>
				</Box>
				{isCommentTooLong && isCollapsed && (
					<FakeLink
						onClick={expand}
						data-testid="show-full-text"
						data-qa="show-full-text"
						sx={{
							display: 'inline-block',
							color: 'black',
						}}
					>
						<Translate value="corgi.petition_gamma.details.description_show_full_text" />
					</FakeLink>
				)}
			</Box>
		</Flex>
	);
}
