import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import {
	GammaPetitionPageUserInfo,
	type GammaPetitionPageUserInfoQuery,
	type GammaPetitionPageUserInfoQueryVariables,
} from './userPetitionInfo.graphql';

type PartialPetition = NonNullable<GammaPetitionPageUserInfoQuery['petition']>;

export async function getUserPetitionInfo(
	slugOrId: string,
	{ gql: { fetch } }: UtilityContext,
): Promise<PartialPetition> {
	const { data } = await fetch<GammaPetitionPageUserInfoQuery, GammaPetitionPageUserInfoQueryVariables>({
		query: GammaPetitionPageUserInfo,
		variables: { slugOrId },
		rejectOnError: false,
	});
	if (!data?.petition) {
		throw new Error('failed to retrieve petition info');
	}
	return data.petition;
}
