import type { JSX } from 'react';

import { FakeLink } from '@change-corgi/design-system/components/actions';
import { Box } from '@change-corgi/design-system/layout';
import { Text } from '@change-corgi/design-system/typography';

import { useEmailDm } from '../hooks/useEmailDmClick';

export function EmailDm({ dmEmail, dmName }: { dmEmail: string; dmName: string }): JSX.Element {
	const {
		data: { emailHref },
		actions: { handleEmailDmClick },
	} = useEmailDm({ dmEmail, dmName });

	return (
		<>
			{emailHref ? (
				<Box my={8}>
					<Text size="caption" fontWeight="bold" as="p">
						Make your petition known to {dmName}
					</Text>
					<FakeLink variant="primary" sx={{ fontSize: '12px' }} onClick={handleEmailDmClick}>
						Send them an email
					</FakeLink>
				</Box>
			) : null}
		</>
	);
}
