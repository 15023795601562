import { useTracking } from '@change-corgi/core/react/tracking';

import { usePetitionDetailsPageData, usePetitionDetailsPageFcm } from 'src/app/pages/petitionGamma/details/pageContext';
import type { DecisionMaker } from 'src/app/pages/petitionGamma/details/shared/types';

import { useDecisionMakerCardsModal } from '../components/DecisionMakerCard/components/DecisionMakerCardsModal';

import { usePrimaryDecisionMakerConnection } from './usePrimaryDecisionMakerConnection';

export type AllDmResponses = ReadonlyArray<{
	readonly id: string;
	readonly decisionMaker: { readonly id: string; readonly displayName: string };
}>;

type Result = ModelHookResult<
	{
		displayedDMs: DecisionMaker[];
		displayShowAllButton: boolean;
		allDmResponses: AllDmResponses;
		shouldTrackDmEmailCtaView: boolean;
		totalDMsCount: number;
		remainingDMsCount: number;
	},
	{
		handleViewMoreClick: () => void;
	}
>;

const DISPLAYED_DM_COUNT = 3;

export function useDecisionMakerCards(): Result {
	const fcm = usePetitionDetailsPageFcm();
	const openDmListModal = useDecisionMakerCardsModal();
	const track = useTracking();

	const {
		data: { decisionMakers, totalCount: totalDMsCount },
	} = usePrimaryDecisionMakerConnection();

	const displayedDMs = decisionMakers.slice(0, DISPLAYED_DM_COUNT);
	const shouldTrackDmEmailCtaView = displayedDMs.some((dm) => Boolean(dm.email));

	const pageData = usePetitionDetailsPageData();
	const {
		data: {
			petition: { dmResponsesConnection },
		},
	} = pageData;

	const handleViewMoreClick = () => {
		void track('petition_gamma_decision_makers_view_more_btn_click');
		openDmListModal({ pageData, fcm });
	};

	return {
		data: {
			displayedDMs,
			displayShowAllButton: totalDMsCount > DISPLAYED_DM_COUNT,
			allDmResponses: dmResponsesConnection.nodes,
			shouldTrackDmEmailCtaView,
			totalDMsCount,
			remainingDMsCount: totalDMsCount - DISPLAYED_DM_COUNT,
		},
		actions: {
			handleViewMoreClick,
		},
	};
}
