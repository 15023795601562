import { useEffect } from 'react';

import pick from 'lodash/fp/pick';

import { useTracking } from '@change-corgi/core/react/tracking';
import { getWindowSafe } from '@change-corgi/core/window';
import type { TrackingProperties } from '@change-corgi/tracking-properties';

import { useQueryString } from 'src/app/shared/hooks/location';

import { useCaptchaEnabled } from '../captcha';
import { usePetitionDetails } from '../petitionDetails';
import { useUserInfo } from '../userInfo';

import { useTrackingPetitionStatus } from './shared/petitionStatus';

type ViewEventQueryParams =
	| 'algorithm'
	| 'fb_action_types'
	| 'fb_ref'
	| 'grid_position'
	| 'original_footer_petition_id'
	| 'recruit_context'
	| 'recruit_variation'
	| 'source_location';
type ViewEventQueryParamsProp = Readonly<Partial<Record<ViewEventQueryParams, string>>>;

function queryParams(query: ViewEventQueryParamsProp): TrackingProperties {
	const gridPosition = !!query.grid_position && query.grid_position.match(/^\d+$/) ? query.grid_position : undefined;

	return {
		grid_position: gridPosition,
		...pick([
			'source_location',
			'algorithm',
			'original_footer_petition_id',
			'fb_ref',
			'fb_action_types',
			'recruit_variation',
			'recruit_context',
		] satisfies ReadonlyArray<keyof TrackingProperties>)(query),
	};
}

function viewportTrackingParams(): TrackingProperties | undefined {
	const viewport = getWindowSafe()?.visualViewport;
	if (!viewport) {
		return undefined;
	}
	return { viewport_width: viewport.width, viewport_height: viewport.height };
}

export function useTrackPageView(): void {
	const {
		id,
		promotable,
		movements,
		score,
		signatureState: { signatureCount },
		tagsConnection,
		geoReach,
		relevantLocationLocalizedName,
		recentSignersConnection,
	} = usePetitionDetails();
	const movementId = movements.nodes[0]?.id;
	const { signed, ownPetition } = useUserInfo();
	const track = useTracking();

	const captcha = useCaptchaEnabled();
	const query = useQueryString();
	const status = useTrackingPetitionStatus();

	useEffect(() => {
		void track('petition_view', {
			petition_id: `petition_${id}`,
			signed,
			movement_id: movementId,
			promotable,
			petition_status: status,
			captcha,
			video: false,
			signature_count: signatureCount.displayed,
			own_petition: ownPetition,
			petition_strength_score: score,
			show_sign: query.show_sign === 'true',
			category_tags: tagsConnection?.nodes.map((tag) => tag.name) ?? [],
			geo_reach: geoReach ?? undefined,
			relevant_location_localized_name: relevantLocationLocalizedName ?? undefined,
			recent_signers_count: recentSignersConnection.edges.length,
			...viewportTrackingParams(),
			...queryParams(query),
		});
		// only send the view event once per id
		// for instance, we don't want a change in the query params to trigger a new event
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);
}
