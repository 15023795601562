import { useCallback, useState } from 'react';
import type { JSX } from 'react';

import qs from 'qs';

import { memo } from '@change-corgi/core/react/core';
import { Translate, TranslatePluralHtml } from '@change-corgi/core/react/i18n';
import { VisuallyHidden } from '@change-corgi/design-system/a11y';
import { Link } from '@change-corgi/design-system/components/actions';
import { Icon } from '@change-corgi/design-system/components/icon';
import { iconExpandLess, iconExpandMore } from '@change-corgi/design-system/icons';
import { Box, Flex } from '@change-corgi/design-system/layout';
import { buttonResetStyles } from '@change-corgi/design-system/reset';
import { Text } from '@change-corgi/design-system/typography';

import { usePetitionPageMovementLinkTracking } from '../../../hooks/tracking/petitionPageMovementLink';

type MovementCardProps = {
	movement: { slug: string; objective: string; petitionCount: number };
	petitionId: string;
	alwaysExpanded?: boolean;
};

export const MovementCard = memo(function MovementCard({
	movement: { slug, objective, petitionCount },
	petitionId,
	alwaysExpanded,
}: MovementCardProps): JSX.Element {
	const [displayedInnerBox, setDisplayedInnerBox] = useState(alwaysExpanded);
	const toggleInnerBox = useCallback(() => setDisplayedInnerBox((val) => !val), [setDisplayedInnerBox]);
	const { trackClick } = usePetitionPageMovementLinkTracking();

	const onMovementNameLinkClick = useCallback((): void => {
		trackClick('movement_name');
	}, [trackClick]);

	const onMovementLearnMoreLinkClick = useCallback((): void => {
		trackClick('learn_more');
	}, [trackClick]);

	const movementUrl = `/m/${slug}${qs.stringify(
		{
			source_location: 'petition_page',
			source_event: 'movement_indicator',
			petition_id: petitionId,
		},
		{ addQueryPrefix: true },
	)}`;

	return (
		<Box
			variant="bordered"
			backgroundColor="primary-greyBackground"
			sx={{
				borderWidth: '0px',
				borderRadius: 'standard',
			}}
		>
			<Flex
				mx={16}
				sx={{
					alignItems: 'center',
					// removing the border width to get the desired height when collapsed
					height: 44 - 1 * 2,
				}}
			>
				<Text as="div" sx={{ flex: 1, fontWeight: 'bold' }} ellipsis mr={16}>
					<Translate value="corgi.movement.petition.movement" />
					<Link
						mx={4}
						to={movementUrl}
						onClick={onMovementNameLinkClick}
						data-testid="movement-name-link"
						data-qa="movement-name-link"
					>
						{objective}
					</Link>
				</Text>
				{!alwaysExpanded && (
					<button
						type="button"
						sx={{ ...buttonResetStyles, display: 'flex', alignItems: 'center' }}
						onClick={toggleInnerBox}
						aria-expanded={displayedInnerBox}
						data-testid="toggle-button"
						data-qa="toggle-button"
					>
						<Icon icon={displayedInnerBox ? iconExpandLess : iconExpandMore} size="xxl" />
						{/* using this approach for a11y for wider screen reader support
							https://www.sarasoueidan.com/blog/accessible-icon-buttons/ (technique #1) */}
						<VisuallyHidden>
							<Translate value="corgi.petition.details.movement.toggle_details" />
						</VisuallyHidden>
					</button>
				)}
			</Flex>
			{displayedInnerBox && (
				<Text as="div" paddingBottom={8} mx={16}>
					{petitionCount === 1 ? (
						<Translate value="corgi.movement.petition.belongs.first" replacements={{ name: objective }} />
					) : (
						<TranslatePluralHtml
							value="corgi.movement.petition.belongs.plural"
							count={petitionCount}
							replacements={{ name: objective, petitionCount }}
						/>
					)}
					<Link
						pl={4}
						to={movementUrl}
						onClick={onMovementLearnMoreLinkClick}
						data-testid="learn-more-link"
						data-qa="learn-more-link"
					>
						<Translate value="corgi.movement.petition.learn_more" />
					</Link>
				</Text>
			)}
		</Box>
	);
});
