import { useCallback } from 'react';

import { useI18n } from '@change-corgi/core/react/i18n';
import type { TrackingProperties } from '@change-corgi/tracking-properties';

import { useCampaignTracking } from 'src/app/shared/hooks/campaignTracking';

import type { SupporterComment } from '../../../../shared/types';
import { useCanReportComment } from '../useCanReportComment';

type Result = ModelHookResult<
	{
		canReportComment: boolean;

		a11yLabel: string;
	},
	{
		handleReportClick: () => void;
	}
>;

export function useReportComment({ petitionId, comment }: { petitionId: string; comment: SupporterComment }): Result {
	const { track } = useCampaignTracking();
	const { translate } = useI18n();

	const { canReportComment, currentUser } = useCanReportComment(comment);

	const handleReportClick = useCallback(() => {
		let payload: TrackingProperties = {
			petition_id: petitionId,
			reporter_id: currentUser?.id,
		};
		payload = { ...payload, comment_id: comment.id, comment_creator_id: comment.user.id };

		void track('petition_gamma_supporter_comment_report_click', payload);
	}, [currentUser, petitionId, track, comment]);

	return {
		data: {
			canReportComment,
			a11yLabel: translate('corgi.petition.details.comments.actions.report_a11y', {
				author: comment.user.displayName,
				date: comment.createdAtLocalized,
			}),
		},
		actions: {
			handleReportClick,
		},
	};
}
