import type { MutableRefObject } from 'react';
import { useRef } from 'react';

import { checkReduceMotion } from 'src/app/pages/petitionGamma/details/shared/utilities';

import type { SectionId } from './index';

type IdToRef = Partial<Record<SectionId, MutableRefObject<HTMLDivElement | null>>>;
type Result = ModelHookResult<
	{
		refs: IdToRef;
	},
	{
		scrollToSection: (id: SectionId) => void;
	}
>;

/*
 * The refs object is a Record of Ref objects, where each key is a SectionId and each value is a Ref object.
 * Each Ref object contains a reference to the corresponding section element.
 * Will need to add new refs for other sections as needed.
 */
export function useScrollContextValue(): Result {
	const prefersReducedMotion = checkReduceMotion();
	const refs: IdToRef = {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		'decision-makers': useRef<HTMLDivElement | null>(null),
		updates: useRef<HTMLDivElement | null>(null),
		// eslint-disable-next-line @typescript-eslint/naming-convention
		'media-mentions': useRef<HTMLDivElement | null>(null),
		// eslint-disable-next-line @typescript-eslint/naming-convention
		'supporter-voices': useRef<HTMLDivElement | null>(null),
	};

	const scrollToSection = (id: SectionId) => {
		const ref = refs[id];
		if (!ref?.current) return;

		ref.current.scrollIntoView({ behavior: prefersReducedMotion ? 'auto' : 'smooth' });
	};

	return { data: { refs }, actions: { scrollToSection } };
}
